/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  CircularProgress,
  Container,
  Box,
  Snackbar,
  OutlinedInput,
    FormControlLabel,
    Chip,
    SelectChangeEvent,
    Switch
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Footer from './components/Footer'
import Banner from "./components/Banner";
import Logo from "./components/Logo";
import SingleSelect from "./components/SingleSelect";

const API_ENDPOINT = process.env.REACT_APP_FAST_API_ENDPOINT as string;
const greenButtonStyle = {
    backgroundColor: "#4CAF50",
    color: "white",
    "&:hover": {
        backgroundColor: "#45a049",
    },
};

const outlinedInputStyles = css`
.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: green !important;
}
.MuiInputLabel-outlined.Mui-focused {
  color: green !important;
}

`;
const appContainerStyle: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
};

type JsonData = {
    location: string;
    listing_type: string;
    file_type: string;
    mls_only: boolean;
    past_days?: number | string;
    radius?: number | string;
};
const App: React.FC = () => {
    const [fileType, setFileType] = useState<string>("");
    const [listingType, setListingType] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [keepDuplicates, setKeepDuplicates] = useState(false);
    const [pastDays, setPastDays] = useState<string | number>("");
    const [radius, setRadius] = useState<string | number>("");

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };
    const handleFileTypeChange = (event: SelectChangeEvent<string>) => {
    setFileType(event.target.value);
};
    const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

    const handleSubmit = () => {
        let newErrors = {};

        if (!fileType)
            newErrors = { ...newErrors, fileType: "Must select a File Type" };
        if (!listingType)
            newErrors = { ...newErrors, listingType: "Must select a Listing Type" };
        if (!location)
            newErrors = { ...newErrors, location: "Location cannot be blank" };
        if (pastDays !== "" && (pastDays < 1 || pastDays > 1000))
    newErrors = { ...newErrors, pastDays: "Must input a number between 1 and 1000" };
         if (radius !== "" && (radius < 1 || radius > 100))
    newErrors = { ...newErrors, radius: "Must input a number between 1 and 100" };



        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);

            setSnackbarMessage("Please fill in all required fields correctly!");
            setOpenSnackbar(true);
            return;
        }

        const jsonData: JsonData = {
    location: location,
    listing_type: listingType,
    file_type: fileType,
    mls_only: keepDuplicates,
};

        if (pastDays !== "") {
        jsonData.past_days = pastDays;
        }
        if (radius !== "") {
        jsonData.radius = radius;
        }
        console.log(jsonData)

        setIsLoading(true);

       axios
    .post(API_ENDPOINT, jsonData, {
        responseType: "blob",
    })
    .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;

        // Getting the current date and time
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}_${currentDate.getHours()}-${currentDate.getMinutes()}-${currentDate.getSeconds()}`;

        const fileExtension = fileType === "excel" ? ".xlsx" : ".csv";
        link.setAttribute("download", `HomeHarvest_${formattedDate}${fileExtension}`);

        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    })
            .catch((error) => {
               if (error.response && error.response.data instanceof Blob) {
        error.response.data.text().then((errorMessage: string) => {
            const parsedError = JSON.parse(errorMessage);
            console.error("Error calling API:", parsedError.detail);
            setSnackbarMessage(parsedError.detail || "Error calling API. Please try again.");
            setOpenSnackbar(true);
        });
    } else {
        console.error("Error calling API:", error);
        setSnackbarMessage("Error calling API. Please try again.");
        setOpenSnackbar(true);
    }
            })
            .finally(() => {
                setIsLoading(false);
            });

        setErrors({});
    };


    return (
        <div style={appContainerStyle} css={outlinedInputStyles}>
            <Container maxWidth="md">
                <Box py={4} display="flex" flexDirection="column" alignItems="center">

                    <Logo />




                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Location"
                        value={location}
                        onChange={(e) => setLocation(e.target.value)}
                        margin="normal"
                    />
                    <SingleSelect
    label="Listing Type"
    value={listingType}
    onChange={(e) => setListingType(e.target.value as string)}
    options={[
        { label: 'For Sale', value: 'for_sale' },
        { label: 'Sold', value: 'sold' },
        { label: 'Pending', value: 'pending' },
        { label: 'For Rent', value: 'for_rent' },
    ]}
/>


                    <SingleSelect
                        label="File Type"
                        value={fileType}
                        onChange={handleFileTypeChange}
                        options={[
                            {value: 'csv', label: 'CSV'},
                            {value: 'excel', label: 'Excel'},
                        ]}
                    />


             <Box display="flex" alignItems="center" justifyContent="center" mt={2}>
                 <Box position="relative" display="inline-block">

    <TextField
    type="number"
    variant="outlined"
    label="Past Days"
    value={pastDays}
    onChange={(e) => setPastDays(e.target.value === "" ? "" : Math.max(1, Math.min(5000, +e.target.value)))}
    margin="normal"
    style={{ width: '110px', marginRight: '20px' }}  // Adjusted width and added margin
    inputProps={{
        min: 1,
        max: 5000,
    }}
/>
                                          <Tooltip title="Number of days to filter properties. Utilizes 'last_sold_date' for 'sold' listing types, and 'list_date' for the other listing types." placement="top-end">
       <IconButton aria-label="info" style={{ position: 'absolute', top: 0, left: '-30px' }}>
            <HelpOutlineIcon fontSize="small" />
        </IconButton>
    </Tooltip>
                     </Box>
<Box position="relative" display="inline-block">
    <TextField
        type="number"
        variant="outlined"
        label="Radius"
        value={radius}
        onChange={(e) => setRadius(e.target.value === "" ? "" : Math.max(1, Math.min(100, +e.target.value)))}
        margin="normal"
        style={{ width: '110px', marginRight: '20px' }}
        inputProps={{
            min: 1,
            max: 100,
        }}
    />
    <Tooltip title="Finds properties within a mile radius if the location is set to a specific address." placement="top-end">
       <IconButton aria-label="info" style={{ position: 'absolute', top: 0, right: '-10px' }}>
            <HelpOutlineIcon fontSize="small" />
        </IconButton>
    </Tooltip>
</Box>

</Box>
                    <Box position="relative" display="inline-block">
                        <FormControlLabel
        control={
            <Switch
                checked={keepDuplicates}
                onChange={(e) => setKeepDuplicates(e.target.checked)}
                style={{ color: keepDuplicates ? '#4CAF50' : undefined, marginRight: "10px" }}
            />
        }
        label="MLS Only"
    />
                         <Tooltip title="Retrieves only properties that have an MLS ID. This is mainly applicable to Sold listing types." placement="top-end">
       <IconButton aria-label="info" style={{ position: 'absolute', top: 0, right: '-20px' }}>
            <HelpOutlineIcon fontSize="small" />
        </IconButton>
    </Tooltip>
                    </Box>


                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isLoading}
                        style={{
                            marginTop: "20px",
                            ...(isLoading ? {} : greenButtonStyle),
                        }}
                    >
                        {isLoading ? "Loading..." : "Harvest"}
                    </Button>
                    {isLoading && (
                        <CircularProgress
                            size={24}
                            style={{ marginLeft: 10, marginTop: "16px" }}
                        />
                    )}
                <Snackbar
    anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    open={openSnackbar}
    onClose={handleCloseSnackbar}
    message={snackbarMessage}
    autoHideDuration={6000}
    action={[
        <Button
            key="close"  // <- Add a unique key here
            color="secondary"
            size="small"
            onClick={handleCloseSnackbar}
            style={{ color: '#4CAF50' }}
        >
            CLOSE
        </Button>,
    ]}
/>
                </Box>
            </Container>

            <Footer />
        </div>
    );
};

export default App;
