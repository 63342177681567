import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import styles from './Footer.module.css';
import FeedbackForm from '../FeedbackForm';

const Footer: React.FC = () => {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const handleOpenFeedback = () => {
    setIsFeedbackOpen(true);
  };

  const handleCloseFeedback = () => {
    setIsFeedbackOpen(false);
  };

  return (
    <div className={styles.footerContainer} >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

        {/* Left Box */}
        <Box ml={3}> {/* Updated margin */}
          <Typography component="span">
            <a href="https://usejobspy.com" target="_blank" rel="noopener noreferrer">
              Try our other project
            </a>
          </Typography>
        </Box>

        {/* Center Box */}
        <Box>
          <Button
            href="https://github.com/ZacharyHampton/HomeHarvest"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<FontAwesomeIcon icon={faGithub} style={{ color: 'black', fontSize: '24px' }} />}
            className={styles.githubButton}
          >
            {!isSmallScreen && 'View on GitHub'}
          </Button>
        </Box>

        {/* Right Box */}
        <Box mr={1.5}> {/* Updated margin */}
          <Button
            onClick={handleOpenFeedback}
            className={styles.feedbackButton}
          >
            Leave Feedback
          </Button>
        </Box>
      </div>

      <FeedbackForm
        open={isFeedbackOpen}
        onClose={handleCloseFeedback}
      />
    </div>
  );
};

export default Footer;
