import React from "react";
import styles from './Logo.module.css'

const Logo: React.FC = () => {
    return (
        <img
          src={`${process.env.PUBLIC_URL}/HomeHarvest.png`}
          alt="Home Harvest"
          className={styles.logoStyle}
        />
    )
}

export default Logo;
